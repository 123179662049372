body {
  font-family: "utopia_seriff";
}

code {
  font-family: "utopia_seriff";
}

@font-face {
  font-family: "utopia_seriff";
  src: url("./fonts/utopia_seriff.ttf");
}

a:link {
  color: red;
}
a:hover {
  color: rgb(86, 176, 250);
}
a:visited {
  color: rgb(240, 83, 11);
}
